import { FedexDeliveryServiceTypes } from "./FedexSettings";
import { UpsDeliveryServiceTypes } from "./UpsSettings";
import { UspsDeliveryServiceTypes } from "./UspsSettings";

export interface DeliveryService {
    type: FedexDeliveryServiceTypes | UpsDeliveryServiceTypes | UspsDeliveryServiceTypes;
    id: string;
    surcharge: number;
    surcharge_type: DeliveryServiceSurchargeType;
    display_name: string;
    is_enabled: boolean;
}

export enum DeliveryServiceSurchargeType {
    FLAT_AMOUNT = 'FLAT_AMOUNT',
    PERCENTAGE = 'PERCENTAGE',
}

