import { DeliveryService } from "./DeliveryService";
import { FedexSettings } from "./FedexSettings";
import { UpsSettings } from "./UpsSettings";
import { UspsSettings } from "./UspsSettings";

export enum RealTimeRateType {
    FEDEX = 'fedex',
    UPS = 'ups',
    USPS = 'usps',
};

export interface RealTimeRateSettings {
    id: string;
    is_enabled: boolean;
    display_name: string;
    rate_type: RealTimeRateType;
    client_id: string;
    client_secret: string;
    account_number?: string;
    delivery_services: DeliveryService[];
};


export interface RealTimeRateSettingsMap {
    [RealTimeRateType.FEDEX]: FedexSettings;
    [RealTimeRateType.UPS]: UpsSettings;
    [RealTimeRateType.USPS]: UspsSettings;
};

