import { useState } from "react";

import useWrappedSwr from "./use-swr";
import { useAppContext } from "./../context/AppContext";
import { RealTimeRateType, RealTimeRateSettingsMap, RealTimeRateSettings } from "../types/RealTimeRateSettings";

function useRealTimeRateSettings({
    type,
    currentZone,
}: {
    type: RealTimeRateType;
    currentZone: string;
}) {
    const { fetchFromServer } = useAppContext();
    const { data, error, isLoading, mutate } = useWrappedSwr<RealTimeRateSettingsMap[RealTimeRateType][]>(`/real-time-rate/${type}/${currentZone}`);

    const settings = data?.status === 200 ? data?.data : undefined;

    const enable = async (id: string) => {
        try {
            await mutate({
                ...data,
                data: data?.data?.map((item: RealTimeRateSettingsMap[RealTimeRateType]) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            is_enabled: true,
                        };
                    }
                    return item;
                }),
            }, {
                revalidate: false,
            });

            await fetchFromServer(`/real-time-rate/${type}/${currentZone}/${id}`, {
                method: "PUT",
                body: JSON.stringify({ is_enabled: true }),
            });
        } catch (error) {
            console.error(error);
        }
    };

    const disable = async (id: string) => {
        try {
            await mutate({
                ...data,
                data: data?.data?.map((item: RealTimeRateSettingsMap[RealTimeRateType]) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            is_enabled: false,
                        };
                    }
                    return item;
                }),
            }, {
                revalidate: false,
            });

            await fetchFromServer(`/real-time-rate/${type}/${currentZone}/${id}`, {
                method: "PUT",
                body: JSON.stringify({ is_enabled: false }),
            });
        } catch (error) {
            console.error(error);
        }
    };

    const submitSettings = async (id: string, newSettings: Partial<RealTimeRateSettings>) => {
        try {
            await mutate({
                ...data,
                data: data?.data?.map((item: RealTimeRateSettingsMap[RealTimeRateType]) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            ...newSettings,
                        };
                    }
                    return item;
                }),
            }, {
                revalidate: false,
            });

            await fetchFromServer(`/real-time-rate/${type}/${currentZone}/${id}`, {
                method: "PUT",
                body: JSON.stringify(newSettings),
            });

            await mutate();
        } catch (error) {
            console.error(error);
        }
    };
    

    return {
        settings,
        isLoading,
        error,
        enable,
        disable,
        submitSettings,
    };
}

export default useRealTimeRateSettings;
