import { useMemo } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    Box,
    Form,
    Button,
    H3,
    Flex,
    Text,
} from "@bigcommerce/big-design";

import { AddIcon, DeleteIcon } from "@bigcommerce/big-design-icons";

import {
    ShipBySettings as ShipBySettingsType,
    StaticQuoteType,
} from "../../../types/staticQuoteSettings";

import Input from "../../FormControls/Input";
import Select from "../../FormControls/Select";
import DollarIcon from "../../UI/DollarIcon";

const schema = yup.object({
    display_name: yup.string(),
    charge_shipping: yup.string().required('Charge Shipping is required'),
    default_shipping_cost: yup.number(),
    type: yup.string().required('Type is required'),
    weight_unit: yup.string(),
    ranges: yup.array().of(yup.object({
        id: yup.string(),
        from_value: yup.number().required('From is required'),
        to_value: yup.number().moreThan(yup.ref('from_value'), 'To must be greater than From').required('To is required'),
        cost: yup.number().required('Cost is required'),
    })).min(1, 'At least one range is required'),
});

interface ShipBySettingsProps {
    initialSettings: ShipBySettingsType;
    otherCarrierNames: string[];
    onSubmit: (settings: Partial<ShipBySettingsType>) => Promise<void>;
}

const ShipBy: React.FC<ShipBySettingsProps> = ({
    initialSettings,
    otherCarrierNames,
    onSubmit,
}) => {
    const schema = useMemo(() => {
        return yup.object({
            display_name: yup.string().notOneOf(otherCarrierNames, 'Display name must be unique'),
            charge_shipping: yup.string().required('Charge Shipping is required'),
            default_shipping_cost: yup.number(),
            type: yup.string().required('Type is required'),
            weight_unit: yup.string(),
            ranges: yup.array().of(yup.object({
                id: yup.string(),
                from_value: yup.number().required('From is required'),
                to_value: yup.number().moreThan(yup.ref('from_value'), 'To must be greater than From').required('To is required'),
                cost: yup.number().required('Cost is required'),
            })).min(1, 'At least one range is required'),
        });
    }, [otherCarrierNames]);

    const methods = useForm<Partial<ShipBySettingsType>>({
        defaultValues: {
            ...initialSettings,
            ranges: initialSettings?.ranges,
            display_name: initialSettings?.display_name,
            charge_shipping: initialSettings?.charge_shipping,
            default_shipping_cost: initialSettings?.default_shipping_cost,
            type: initialSettings?.type,
            weight_unit: initialSettings?.weight_unit,
        },
        // @ts-ignore
        resolver: yupResolver(schema),
    });

    const chargeShipping = methods.watch('charge_shipping');
    const type = methods.watch('type');
    const weightUnit = methods.watch('weight_unit');

    const prefix = chargeShipping === 'by_order_total' ? '$' : null;
    const postfix = chargeShipping === 'by_weight' ? weightUnit : null;
    const costPrefix = type === 'flat' ? '$' : null;
    const costPostfix = type === 'percent' ? '%' : null;
    const costLabel = type === 'percent' ? 'Cost (% of order total)' : 'Cost';

    const handleAddRange = () => {
        methods.setValue('ranges', [...methods.getValues('ranges'), { from_value: 0, to_value: 0, cost: 0 }]);
    };

    const handleRemoveRange = (index: number) => {
        const ranges = methods.getValues('ranges');
        ranges.splice(index, 1);
        methods.setValue('ranges', ranges);
    };

    const handleSubmit: SubmitHandler<Partial<ShipBySettingsType>> = async (data) => {
        console.log(data);
        await onSubmit(data);
    };


    return (
        <Form id={StaticQuoteType.SHIP_BY} onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
            <Input
                label="Display Name"
                type="text"
                control={methods.control}
                name="display_name"
            />
            {/* @ts-ignore */}
            <Select
                label="Charge Shipping"
                options={[
                    { value: 'by_weight', content: 'By Weight' },
                    // { value: 'by_order_total', content: 'By Order Total' },
                    // { value: 'by_product_type', content: 'By Product Type' },
                ]}
                control={methods.control}
                name="charge_shipping"
            />

            {
                methods.watch('charge_shipping') === 'by_weight' && (
                    <Select
                        label="Weight Unit"
                        options={[
                            // {
                            //     value: 'oz',
                            //     content: 'oz',
                            // },
                            {
                                value: 'lb',
                                content: 'lb',
                            },
                            // {
                            //     value: 'g',
                            //     content: 'g',
                            // },
                            {
                                value: 'kg',
                                content: 'kg',
                            },
                        ]}
                        control={methods.control}
                        name="weight_unit"
                    />
                )
            }

            {
                methods.watch('charge_shipping') === 'by_product_type' && (
                    <Select
                        label="Product Type"
                        options={[{
                            value: 'Wine',
                            content: 'Wine',
                        },
                        {
                            value: 'Spirit',
                            content: 'Spirit',
                        },
                        ]}
                        control={methods.control}
                        name="product_type"
                    />
                )
            }

            <Input
                label="Default Shipping Cost"
                type="number"
                control={methods.control}
                name="default_shipping_cost"
                iconLeft={<DollarIcon />}
            />
            {/* @ts-ignore */}
            <Select
                label="Type"
                options={[
                    { value: 'flat', content: '$' },
                    { value: 'percent', content: '% of order total' },
                ]}
                onOptionChange={() => {
                    console.log('select');
                }}
                control={methods.control}
                name="type"
            />

            <H3>Ranges</H3>

            {methods.watch('ranges').map((range, index) => {
                const ranges = methods.getValues('ranges');
                const isFirst = index === 0;
                const isLast = index === ranges.length - 1;
                // only show the delete button if it's not the first range
                const showDeleteButton = !isFirst;
                // only show the add button when it's the last range
                const showAddButton = isLast;

                

                return (
                    <Flex key={index} alignItems="flex-end" marginBottom="small">
                        <Range
                            key={index}
                            control={methods.control}
                            name={`ranges.${index}`}
                            prefix={prefix}
                            postfix={postfix}
                            costPrefix={costPrefix}
                            costPostfix={costPostfix}
                        />
                        <Button
                            variant="subtle"
                            iconOnly={<DeleteIcon />}
                            onClick={() => handleRemoveRange(index)}
                            type="button"
                            disabled={!showDeleteButton}
                        />
                        <Button
                            variant="subtle"
                            iconOnly={<AddIcon />}
                            onClick={handleAddRange}
                            type="button"
                            disabled={!showAddButton}
                        />
                    </Flex>
                )
            })}
        </Form>
    );
};

const Range = ({ control, name, prefix, postfix, costPrefix, costPostfix }) => {
    return (
        <Flex>
            <Box>
                <Input
                    label="From"
                    type="number"
                    control={control}
                    name={`${name}.from_value`}
                    iconLeft={prefix ? <Text>{prefix}</Text> : null}
                    iconRight={postfix ? <Text>{postfix}</Text> : null}
                />
            </Box>
            <Box marginLeft="small">
                <Input
                    label="Up to (but not including)"
                    type="number"
                    control={control}
                    name={`${name}.to_value`}
                    iconLeft={prefix ? <Text>{prefix}</Text> : null}
                    iconRight={postfix ? <Text>{postfix}</Text> : null}
                />
            </Box>
            <Box marginLeft="small">
                <Input
                    label="Cost"
                    type="number"
                    control={control}
                    name={`${name}.cost`}
                    iconLeft={costPrefix ? <Text>{costPrefix}</Text> : null}
                    iconRight={costPostfix ? <Text>{costPostfix}</Text> : null}
                />
            </Box>
        </Flex>
    );
}

export default ShipBy;
