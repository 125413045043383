import { RealTimeRateSettings } from "./RealTimeRateSettings";

export enum FedexDeliveryServiceTypes {
    FIRST_OVERNIGHT = 'FIRST_OVERNIGHT',
    PRIORITY_OVERNIGHT = 'PRIORITY_OVERNIGHT',
    STANDARD_OVERNIGHT = 'STANDARD_OVERNIGHT',
    FEDEX_2_DAY_AM = 'FEDEX_2_DAY_AM',
    FEDEX_2_DAY = 'FEDEX_2_DAY',
    FEDEX_EXPRESS_SAVER = 'FEDEX_EXPRESS_SAVER',
    FEDEX_GROUND = 'FEDEX_GROUND',
    GROUND_HOME_DELIVERY = 'GROUND_HOME_DELIVERY',
    INTERNATIONAL_PRIORITY = 'INTERNATIONAL_PRIORITY',
    INTERNATIONAL_ECONOMY = 'INTERNATIONAL_ECONOMY',
    INTERNATIONAL_FIRST = 'INTERNATIONAL_FIRST',
    INTERNATIONAL_PRIORITY_EXPRESS = 'INTERNATIONAL_PRIORITY_EXPRESS',
    FEDEX_1_DAY_FREIGHT = 'FEDEX_1_DAY_FREIGHT',
    FEDEX_2_DAY_FREIGHT = 'FEDEX_2_DAY_FREIGHT',
    FEDEX_3_DAY_FREIGHT = 'FEDEX_3_DAY_FREIGHT',
    INTERNATIONAL_PRIORITY_FREIGHT = 'INTERNATIONAL_PRIORITY_FREIGHT',
    INTERNATIONAL_ECONOMY_FREIGHT = 'INTERNATIONAL_ECONOMY_FREIGHT',
    FEDEX_FIRST_FREIGHT = 'FEDEX_FIRST_FREIGHT',
    FEDEX_FREIGHT_PRIORITY = 'FEDEX_FREIGHT_PRIORITY',
    FEDEX_FREIGHT_ECONOMY = 'FEDEX_FREIGHT_ECONOMY'
}

export enum FedexDropOffTypes {
    CONTACT_FEDEX_TO_SCHEDULE = 'CONTACT_FEDEX_TO_SCHEDULE',
    DROPOFF_AT_FEDEX_LOCATION = 'DROPOFF_AT_FEDEX_LOCATION',
    USE_SCHEDULED_PICKUP = 'USE_SCHEDULED_PICKUP',
};

export enum FedexPackagingTypes {
    FEDEX_BOX = 'FEDEX_BOX',
    FEDEX_PAK = 'FEDEX_PAK',
    FEDEX_TUBE = 'FEDEX_TUBE',
    YOUR_PACKAGING = 'YOUR_PACK',
};

export enum FedexPackingMethods {
    PACKAGING = 'PACKAGING',
    WEIGHT_BASED = 'WEIGHT_BASED',
};

export enum FedexDestinationTypes {
    RESIDENTIAL = 'RESIDENTIAL',
    COMMERCIAL = 'COMMERCIAL',
};

export enum FedexRateRequestTypes {
    LIST = 'LIST',
    RATE = 'ACCOUNT',
};

export interface FedexSettings extends RealTimeRateSettings {
    drop_off_type: FedexDropOffTypes;
    packaging_type: FedexPackagingTypes;
    packing_method: FedexPackingMethods;
    include_package_value: boolean;
    destination_type: FedexDestinationTypes;
    rate_request_type: FedexRateRequestTypes;
};