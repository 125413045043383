import { useMemo } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Form } from '@bigcommerce/big-design';
import * as yup from 'yup';

import Input from '../../FormControls/Input';
import Select from '../../FormControls/Select';
import DeliveryServiceTable from '../../Tables/DeliveryServiceTable';

import { RealTimeRateType } from '../../../types/RealTimeRateSettings';
import {
    UspsSettings,
    UspsDeliveryServiceTypes,
    UspsPriceType,
    UspsRateIndicator,
} from '../../../types/UspsSettings';
import { DeliveryServiceSurchargeType } from "../../../types/DeliveryService";


const priceTypeDisplayNames = {
    [UspsPriceType.RETAIL]: 'Retail',
    [UspsPriceType.COMMERCIAL]: 'Commercial',
    [UspsPriceType.CONTRACT]: 'Contract',
}

export type UspsSettingsFormProps = {
    initialSettings: UspsSettings;
    otherCarrierNames: string[];
    onSubmit: (data: Partial<UspsSettings>) => Promise<void>;
}

const uspsPackageTypeDisplayNames = {
    [UspsRateIndicator.FLAT_RATE_LEGAL]: 'Flat Rate Legal Envelope',
    [UspsRateIndicator.FLAT_RATE_PADDED]: 'Flat Rate Padded Envelope',
    [UspsRateIndicator.PARCEL]: 'Parcel',
    [UspsRateIndicator.FLAT_RATE_BOX_SM]: 'Small Flat Rate Box',
    [UspsRateIndicator.FLAT_RATE_BOX_MD]: 'Medium Flat Rate Box',
    [UspsRateIndicator.FLAT_RATE_BOX_LG]: 'Large Flat Rate Box',
}

const UspsSettingsForm: React.FC<UspsSettingsFormProps> = ({
    initialSettings,
    onSubmit,
    otherCarrierNames,
}) => {
    const schema = useMemo(() => {
        return yup.object({
            display_name: yup.string().required('Display Name is required').notOneOf(otherCarrierNames, 'Display name must be unique'),
            rate_type: yup.string().oneOf(Object.values(RealTimeRateType)).required('Rate Type is required'),
            price_type: yup.string().oneOf(Object.values(UspsPriceType)).required('Price Type is required'),
            rate_indicator: yup.string().oneOf(Object.values(UspsRateIndicator)).required('Package Type is required'),
            delivery_services: yup.array().of(yup.object({
                is_enabled: yup.boolean(),
                type: yup.string().oneOf(Object.values(UspsDeliveryServiceTypes)).required('Delivery Service is required'),
                id: yup.string().required('ID is required'),
                surcharge: yup.number().required('Surcharge is required'),
                surcharge_type: yup.string().oneOf(Object.values(DeliveryServiceSurchargeType)).required('Surcharge Type is required'),
            })),
        });
    }, [otherCarrierNames]);

    const methods = useForm<Partial<UspsSettings>>({
        defaultValues: {
            display_name: initialSettings?.display_name || 'USPS',
            rate_type: initialSettings?.rate_type || RealTimeRateType.USPS,
            price_type: initialSettings?.price_type || UspsPriceType.RETAIL,
            rate_indicator: initialSettings?.rate_indicator || UspsRateIndicator.FLAT_RATE_BOX_MD,
            delivery_services: initialSettings?.delivery_services || [],
        },
        // @ts-ignore
        resolver: yupResolver(schema),
    });

    return (
        <Form id={RealTimeRateType.USPS} onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <Input
                control={methods.control}
                name="display_name"
                label="Display Name"
            />
            <Select
                control={methods.control}
                name="price_type"
                label="Price Type"
                options={Object.entries(priceTypeDisplayNames).map(([value, label]) => ({ content: label, value }))}
            />
            <Select
                control={methods.control}
                name="rate_indicator"
                label="Package Type"
                options={Object.entries(uspsPackageTypeDisplayNames).map(([value, label]) => ({ content: label, value }))}
            />
            <Box marginTop='large'>
                <DeliveryServiceTable
                    control={methods.control}
                    deliveryServices={methods.getValues('delivery_services').map((deliveryService, index) => ({
                        ...deliveryService,
                        index,
                    }))}
                />
            </Box>
        </Form>
    );
}

export default UspsSettingsForm;
