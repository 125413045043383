import useSWR, { SWRConfiguration } from 'swr';
import { useAppContext } from '../context/AppContext';

function useWrappedSwr<T = any>(url: string, config?: SWRConfiguration) {
    const { fetchFromServer } = useAppContext();

    return useSWR<{
        data: T;
        status: number;
    }>(() => url, fetchFromServer, config);
}

export default useWrappedSwr;
