import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    Text,
    Form,
} from "@bigcommerce/big-design";

import Input from "../../FormControls/Input";
import Checkbox from "../../FormControls/Checkbox";
import DollarIcon from "../../UI/DollarIcon";

import {
    FreeShippingSettings,
    StaticQuoteType,
} from "../../../types/staticQuoteSettings";

const schema = yup.object({
    limit_to_min_order_value: yup.boolean(),
    min_order_value: yup.number().when('limit_to_min_order_value', {
        is: (value: boolean) => value === true,
        then: (schema) => schema.required('Minimum order value is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
    use_discounted_subtotal: yup.boolean(),
    ignore_fixed_shipping_products: yup.boolean(),
});

interface FreeShippingSettingsFormProps {
    initialSettings: FreeShippingSettings;
    onSubmit: (data: Partial<FreeShippingSettings>) => Promise<void>;
}

const FreeShippingSettingsForm = ({
    initialSettings,
    onSubmit,
}: FreeShippingSettingsFormProps) => {
    const methods = useForm<Partial<FreeShippingSettings>>({
        defaultValues: {
            limit_to_min_order_value: initialSettings.limit_to_min_order_value || false,
            min_order_value: initialSettings?.min_order_value || 0,
            use_discounted_subtotal: initialSettings?.use_discounted_subtotal || false,
        },
        // @ts-ignore
        resolver: yupResolver(schema),
    });

    const handleSubmit: SubmitHandler<Partial<FreeShippingSettings>> = async (data) => {
        await onSubmit(data);
    };

    return (
        <Form id={StaticQuoteType.FREE_SHIPPING} onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
            <Text>
                You are able to limit free shipping to be active only when a certain order total amount is exceeded. Products with fixed price shipping can be made ineligible for free shipping, but the product price will still count toward the minimum order threshold.
            </Text>
            <Checkbox
                label="Limit to orders over"
                name="limit_to_min_order_value"
                control={methods.control}

            />
            <Input
                control={methods.control}
                type="number"
                label="Minimum order value"
                placeholder="Enter amount"
                iconLeft={<DollarIcon />}
                name="min_order_value"
                disabled={methods.watch('limit_to_min_order_value') === false}
            />
            <Checkbox
                label="Use discounted order subtotal"
                name="use_discounted_subtotal"
                control={methods.control}
            />
        </Form>

    );
};

export default FreeShippingSettingsForm;