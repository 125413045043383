
import { Controller } from "react-hook-form";
import { Select as BDSelect, FormGroup } from "@bigcommerce/big-design";

const Select = ({ control, name, label = '', options, required = true, ...otherSelectProps }) => {
    const render = ({ field, fieldState }) => {
        return (
            <FormGroup>
                {/* @ts-ignore */}
                <BDSelect
                    label={label === '' ? null : label}
                    {...otherSelectProps}
                    {...field}
                    options={options}
                    onOptionChange={field.onChange}
                    error={fieldState.error?.message}
                    required={required}
                />
            </FormGroup>
        );
    };

    return (
        <Controller control={control} name={name} render={render} />
    );
};

export default Select;