import styled from 'styled-components';

const DollarIconContainer = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    font-size: 18px;
    justify-content: center;
    align-items: center;
`;

const DollarIcon = () => {
    return <DollarIconContainer>$</DollarIconContainer>;
};

export default DollarIcon;

