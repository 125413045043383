import { StaticQuoteType, StaticRateSettings } from "../types/staticQuoteSettings";
import useStaticRateSettings from "./useStaticRateSettings";

const quoteTypes = [
    // StaticQuoteType.FREE_SHIPPING,
    StaticQuoteType.FLAT_RATE,
    StaticQuoteType.SHIP_BY,
    // StaticQuoteType.PICKUP,
];

const useStaticQuotesData = (currentZone: string) => {
    const quoteData = quoteTypes.map(quoteType => {
        const {
            settings,
            error,
            isLoading,
            enable,
            disable,
            submitSettings,
            create,
            deleteQuote,
        } = useStaticRateSettings({ type: quoteType, currentZone });

        return {
            settings,
            error,
            isLoading,
            type: quoteType,
            enable,
            disable,
            submitSettings,
            create,
            deleteQuote,
        }
    });

    const isLoading = quoteData.some(quote => quote.isLoading);
    const error = quoteData.some(quote => quote.error);

    const enableStaticQuote = async (id: string, quoteType: string) => {
        const quote = quoteData.find(q => q.type === quoteType)

        if (quote) await quote.enable(id);
    };

    const disableStaticQuote = async (id: string, quoteType: string) => {
        const quote = quoteData.find(q => q.type === quoteType);
        if (quote) await quote.disable(id);
    };

    const createUniqueDisplayName = (originalDisplayName: string) => {
        let newDisplayName = originalDisplayName;
        let counter = 1;

        while (quoteData.some(q => q.settings.some(s => s.display_name === newDisplayName))) {
            newDisplayName = `Copy of ${originalDisplayName}`;

            counter++;
        }

        return newDisplayName;
    };

    const createStaticQuote = async (quoteType: StaticQuoteType, newSettings: Partial<StaticRateSettings>) => {
        const {
            display_name,
        } = newSettings;

        const uniqueDisplayName = createUniqueDisplayName(display_name);


        const quote = quoteData.find(q => q.type === quoteType);

        if (quote) await quote.create({ ...newSettings, display_name: uniqueDisplayName });
    };

    const deleteStaticQuote = async (id: string, quoteType: string) => {
        const quote = quoteData.find(q => q.type === quoteType);
        if (quote) await quote.deleteQuote(id);
    };

    const submitSettings = async (id: string, quoteType: string, settings: Partial<StaticRateSettings>) => {
        const quote = quoteData.find(q => q.type === quoteType);
        if (quote) await quote.submitSettings(id, settings);
    };

    return {
        staticQuoteData: quoteData,
        isLoading,
        error,
        enableStaticQuote,
        disableStaticQuote,
        createStaticQuote,
        submitSettings,
        deleteStaticQuote,
    };
};

export default useStaticQuotesData;