import { useMemo } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Form } from '@bigcommerce/big-design';
import * as yup from 'yup';

import Input from '../../FormControls/Input';
import Select from '../../FormControls/Select';
import Checkbox from '../../FormControls/Checkbox';
import DeliveryServiceTable from '../../Tables/DeliveryServiceTable';

import { DeliveryServiceSurchargeType } from '../../../types/DeliveryService';
import { RealTimeRateType } from '../../../types/RealTimeRateSettings';
import {
    UpsSettings,
    UpsDeliveryServiceTypes,
    UpsPackagingType
} from '../../../types/UpsSettings';


export type UpsSettingsFormProps = {
    initialSettings: UpsSettings;
    onSubmit: (data: Partial<UpsSettings>) => Promise<void>;
    otherCarrierNames: string[];
}

const UPSSettingsForm: React.FC<UpsSettingsFormProps> = ({
    initialSettings,
    onSubmit,
    otherCarrierNames,
}) => {
    const schema = useMemo(() => {
        return yup.object({
            display_name: yup.string().required().notOneOf(otherCarrierNames, 'Display name must be unique'),
            rate_type: yup.string().oneOf(Object.values(RealTimeRateType)).required(),
            include_package_value: yup.boolean(),
            use_negotiated_rates: yup.boolean(),
            show_estimated_delivery_date: yup.boolean(),
            packaging_type: yup.string().oneOf(Object.values(UpsPackagingType)).required(),
            delivery_services: yup.array().of(yup.object({
                is_enabled: yup.boolean(),
                type: yup.string().oneOf(Object.values(UpsDeliveryServiceTypes)).required(),
                id: yup.string().required(),
                surcharge: yup.number().required(),
                surcharge_type: yup.string().oneOf(Object.values(DeliveryServiceSurchargeType)).required(),
            })),
        });
    }, [otherCarrierNames]);

    const methods = useForm<Partial<UpsSettings>>({
        defaultValues: {
            display_name: initialSettings?.display_name || 'UPS',
            rate_type: initialSettings?.rate_type || RealTimeRateType.UPS,
            delivery_services: initialSettings?.delivery_services || [],
            include_package_value: initialSettings?.include_package_value || false,
            use_negotiated_rates: initialSettings?.use_negotiated_rates || false,
            show_estimated_delivery_date: initialSettings?.show_estimated_delivery_date || false,
            packaging_type: initialSettings?.packaging_type || UpsPackagingType.CUSTOMER_SUPPLIED,
        },
        // @ts-ignore
        resolver: yupResolver(schema),
    });

    return (
        <Form id={RealTimeRateType.UPS} onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <Input
                control={methods.control}
                name="display_name"
                label="Display Name"
            />
            <Checkbox
                control={methods.control}
                name="include_package_value"
                label="Include Package Value"
            />
            <Checkbox
                control={methods.control}
                name="use_negotiated_rates"
                label="Use Negotiated Rates"
            />
            {/*
            <Checkbox
                control={methods.control}
                name="show_estimated_delivery_date"
                label="Show Estimated Delivery Date"
            /> */}

            <Select
                control={methods.control}
                name="packaging_type"
                label="Packaging Type"
                options={Object.values(UpsPackagingType).map(service => ({
                    content: upsPackageTypeDisplayNames[service],
                    value: service,
                }))}
            />
            <Box marginTop='large'>
                <DeliveryServiceTable
                    control={methods.control}
                    deliveryServices={methods.getValues('delivery_services').map((deliveryService, index) => ({
                        ...deliveryService,
                        index,
                    }))}
                />
            </Box>
        </Form>
    );
}

const upsPackageTypeDisplayNames = {
    [UpsPackagingType.CUSTOMER_SUPPLIED]: 'Customer Supplied',
    [UpsPackagingType.EXPRESS_BOX]: 'Express Box',
    [UpsPackagingType.UPS_LETTER]: 'UPS Letter',
    [UpsPackagingType.PAK]: 'Pak',
    [UpsPackagingType.TUBE]: 'Tube',
    [UpsPackagingType.PACKAGE]: 'Package',
}

export default UPSSettingsForm;
