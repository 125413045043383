import { Control } from "react-hook-form";

import {
    DeliveryService,
    DeliveryServiceSurchargeType,
} from '../../types/DeliveryService';

import Input from '../FormControls/Input';
import Select from '../FormControls/Select';
import Checkbox from '../FormControls/Checkbox';
import { Table } from '@bigcommerce/big-design';


interface DeliveryServicesTableProps {
    deliveryServices: (DeliveryService & { index: number })[];
    control: Control<any>;
}

const surchargeTypeOptions = {
    [DeliveryServiceSurchargeType.FLAT_AMOUNT]: 'Flat Amount',
    [DeliveryServiceSurchargeType.PERCENTAGE]: 'Percentage',
};



const DeliveryServiceTable = ({ deliveryServices, control }: DeliveryServicesTableProps) => {
    const columns = [
        {
            header: 'Mail Classes',
            hash: 'is_enabled',
            render: (row: DeliveryService & { index: number }) => {
                return (
                    <Checkbox
                        name={`delivery_services.${row.index}.is_enabled`}
                        control={control}
                        label={row.display_name}
                    />
                );
            }
        },
        {
            header: 'Surcharge',
            hash: 'surcharge',
            render: (row: DeliveryService & { index: number }) => {
                return (
                    <Input
                        name={`delivery_services.${row.index}.surcharge`}
                        control={control}
                        type="number"
                    />
                );
            }
        },
        {
            header: 'Surcharge Type',
            hash: 'surcharge_type',
            render: (row: DeliveryService & { index: number }) => {
                return (
                    <Select
                        name={`delivery_services.${row.index}.surcharge_type`}
                        control={control}
                        options={Object.values(DeliveryServiceSurchargeType).map(value => ({ value, content: surchargeTypeOptions[value] }))}
                    />
                );
            }
        }
    ];

    return (
        <Table
            columns={columns}
            items={deliveryServices}
        />
    );
}

export default DeliveryServiceTable;