import { useContext, createContext } from "react";
import { AlertProps, Subscriber, createAlertsManager } from '@bigcommerce/big-design';
interface AddAlertConfig extends AlertProps {
  autoDismiss?: boolean;
}

interface PrivateAlert extends AddAlertConfig {
  onClose(): void;
}

export type AlertsManagerType = {
  add: (alert: AddAlertConfig) => string;
  clear: () => PrivateAlert[];
  remove: (key: string) => AlertProps | undefined;
  subscribe: (subscriber: Subscriber) => () => void;
};

type AppContextType = {
  fetchFromServer: (url: string, options?: RequestInit) => Promise<any>;
  alertsManager?: ReturnType<typeof createAlertsManager>;
};

const AppContext = createContext<AppContextType>({
  fetchFromServer: () => Promise.resolve(),
});

export const useAppContext = () => {
  return useContext(AppContext);
};

type AppProviderProps = {
  children: React.ReactNode;
  props: {
    config: {
      api_url: string;
      store_hash: string;
      session_token: string;
    }
  },
  alertsManager?: ReturnType<typeof createAlertsManager>;
};

export const AppProvider: React.FC<AppProviderProps> = ({ children, props, alertsManager }) => {
  const fetchFromServer = async (url: string, options: RequestInit = {}) => {
    const fullServerUrl = `${props.config.api_url}/api/shipping${url}`;
    const headers = options.headers || {};
    headers["Authorization"] = `${props.config.session_token}`;
    headers["Content-Type"] = "application/json";
    headers["Store-Hash"] = props.config.store_hash;
    
    const response = await fetch(fullServerUrl, {
      ...options,
      headers,
    });

    if (process.env.NODE_ENV === 'development') {
      const message = `${options.method || 'GET'} request to ${fullServerUrl} - ${response.statusText} - ${response.status}`;
      console.info(message);
    }


    return {
      data: await response.json(),
      status: response.status,
    }
  };

  return <AppContext.Provider value={{
    fetchFromServer,
    alertsManager,
  }}>{children}</AppContext.Provider>;
};


// 52568