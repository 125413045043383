import { RealTimeRateSettings, RealTimeRateType } from "../types/RealTimeRateSettings";
import useRealTimeRateSettings from "./useRealTimeRateSettings";

const quoteTypes = [
    RealTimeRateType.FEDEX,
    RealTimeRateType.UPS,
    RealTimeRateType.USPS,
];

const useRealTimeQuotesData = (currentZone: string) => {
    const quoteData = quoteTypes.map(quoteType => {
        const {
            settings,
            error,
            isLoading,
            enable,
            disable,
            submitSettings,
        } = useRealTimeRateSettings({ type: quoteType, currentZone });

        return {
            type: quoteType,
            settings,
            error,
            isLoading,
            enable,
            disable,
            submitSettings,
        };
    });

    const isLoading = quoteData.some(quote => quote.isLoading);
    const error = quoteData.some(quote => quote.error);

    const enableRealTimeQuote = async (id: string, quoteType: string) => {
        const quote = quoteData.find(q => q.type === quoteType)

        if (quote) await quote.enable(id);
    };

    const disableRealTimeQuote = async (id: string, quoteType: string) => {
        const quote = quoteData.find(q => q.type === quoteType);
        if (quote) await quote.disable(id);
    };

    const submitSettings = async (id: string, quoteType: string, settings: Partial<RealTimeRateSettings>) => {

        const quote = quoteData.find(q => q.type === quoteType);
        if (quote) await quote.submitSettings(id, settings);
    };

    return {
        quoteData,
        isLoading,
        error,
        enableRealTimeQuote,
        disableRealTimeQuote,
        submitSettings,
    };
};

export default useRealTimeQuotesData;
