import { Controller } from 'react-hook-form';
import { Checkbox as BDCheckbox, FormGroup } from '@bigcommerce/big-design';

const Checkbox = ({ control, name, label = '', disabled = false }) => {
    const render = ({ field, fieldState }) => {
        const onChange = (e) => {
            field.onChange(e.target.checked);
        }


        return (
            <FormGroup>
                <BDCheckbox
                    label={label === '' ? null : label}
                    onChange={onChange}
                    checked={field.value}
                    disabled={disabled}
                />
            </FormGroup>
        );
    }

    return (
        <Controller
            control={control}
            name={name}
            render={render}
        />
    );
};

export default Checkbox;