import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from '@bigcommerce/big-design';
import * as yup from 'yup';

import Input from '../../FormControls/Input';
import { RealTimeRateSettings, RealTimeRateType } from '../../../types/RealTimeRateSettings';

const schema: yup.ObjectSchema<{
    client_id: RealTimeRateSettings['client_id'];
    client_secret: RealTimeRateSettings['client_secret'];
    account_number: RealTimeRateSettings['account_number'];
}> = yup.object({
    client_id: yup.string().required('Client ID is required.'),
    client_secret: yup.string().required('Client secret is required.'),
    account_number: yup.string().required('Account number is required.'),
});

export interface UPSConnectionFormProps {
    initialSettings: RealTimeRateSettings;
    onSubmit: (data: Partial<RealTimeRateSettings>) => Promise<void>;
}

const UPSConnectionForm: React.FC<UPSConnectionFormProps> = ({
    initialSettings,
    onSubmit,
}) => {
    const methods = useForm<Partial<RealTimeRateSettings>>({
        defaultValues: {
            client_id: initialSettings.client_id,
            client_secret: initialSettings.client_secret,
            account_number: initialSettings.account_number,
        },
        resolver: yupResolver(schema),
    });

    return (
        <Form id={RealTimeRateType.UPS} onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <Input
                control={methods.control}
                name="client_id"
                label="Client ID"
                type="password"
            />
            <Input
                control={methods.control}
                name="client_secret"
                label="Client Secret"
                type="password"
            />
            <Input
                control={methods.control}
                name="account_number"
                label="Account Number"
            />
        </Form>
    );
};

export default UPSConnectionForm;