import React from "react";

import {
    Box,
    H2,
    Panel,
    Grid,
    Modal,
} from "@bigcommerce/big-design";

const RateGroup: React.FC<{ children: React.ReactNode, heading: string }> = ({ children, heading }) => {
    return (
        <Box>
            <Box marginBottom="large">
                <H2>{heading}</H2>
            </Box>
            <Panel>
                {children}
            </Panel>
        </Box>
    );
};

export default RateGroup;