import { useMemo } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from "../../FormControls/Input";

import { PickupSettings, StaticQuoteType } from "../../../types/staticQuoteSettings";

import { Box, Form, FormGroup } from "@bigcommerce/big-design";

interface PickupSettingsProps {
    initialSettings: PickupSettings;
    onSubmit: (settings: Partial<PickupSettings>) => Promise<void>;
    otherCarrierNames?: string[];
}

const Pickup = ({
    initialSettings,
    onSubmit,
    otherCarrierNames = [],
}: PickupSettingsProps) => {
    const schema = useMemo(() => {
        return yup.object({
            display_name: yup.string().notOneOf(otherCarrierNames, 'Display name must be unique'),
        });
    }, [otherCarrierNames]);


    const methods = useForm<Partial<PickupSettings>>({
        defaultValues: {
            display_name: initialSettings?.display_name || 'Pickup',
        },
        // @ts-ignore
        resolver: yupResolver(schema),
    });

    const handleSubmit: SubmitHandler<Partial<PickupSettings>> = async (data) => {
        await onSubmit(data);
    };

    return (
        <Box>
            <Form id={StaticQuoteType.PICKUP} onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
                <FormGroup>
                    <Input
                        type="text"
                        label="Display Name"
                        control={methods.control}
                        name="display_name"
                    />
                </FormGroup>
            </Form>
        </Box>
    );
};

export default Pickup;