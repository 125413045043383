import { useState } from "react";

import { StaticQuoteType, StaticQuoteTypeMap, StaticRateSettings } from "../types/staticQuoteSettings";
import useWrappedSwr from "./use-swr";
import { useAppContext } from "./../context/AppContext";

function useStaticRateSettings({
    type,
    currentZone,
}: {
    type: StaticQuoteType;
    currentZone: string;
}) {
    const { fetchFromServer } = useAppContext();
    const { data, error, isLoading, mutate } = useWrappedSwr<StaticQuoteTypeMap[StaticQuoteType][]>(`/static-rate/${type}/${currentZone}`);
    const settings = data?.status === 200 ? data?.data?.sort((a: StaticRateSettings, b: StaticRateSettings) => a.display_name.localeCompare(b.display_name)) : undefined;

    const enable = async (id: string) => {
        try {
            await mutate({
                ...data,
                data: data?.data?.map((item: StaticRateSettings) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            is_enabled: true,
                        };
                    }
                    return item;
                }),
            }, {
                revalidate: false,
            });

            await fetchFromServer(`/static-rate/${type}/${currentZone}/${id}`, {
                method: "PUT",
                body: JSON.stringify({ is_enabled: true }),
            });

            // await mutate();
        } catch (error) {
            console.error(error);

            throw new Error('Failed to enable');
            // setActionError(error.message);
        }
    };

    const disable = async (id: string) => {
        try {
            await mutate({
                ...data,
                data: data?.data?.map((item: StaticRateSettings) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            is_enabled: false,
                        };
                    }
                    return item;
                }),
            }, {
                revalidate: false,
            });

            await fetchFromServer(`/static-rate/${type}/${currentZone}/${id}`, {
                method: "PUT",
                body: JSON.stringify({ is_enabled: false }),
            });

            // await mutate();
        } catch (error) {
            console.error(error);

            throw new Error('Failed to disable');
        }
    };

    const submitSettings = async (id: string, newSettings: Partial<StaticQuoteTypeMap[StaticQuoteType]>) => {
        try {
            await mutate({
                ...data,
                data: data?.data?.map((item: StaticRateSettings) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            ...newSettings,
                        };
                    }
                    return item;
                }),
            }, {
                revalidate: false,
            });

            await fetchFromServer(`/static-rate/${type}/${currentZone}/${id}`, {
                method: "PUT",
                body: JSON.stringify(newSettings),
            });

            await mutate();
        } catch (error) {
            console.error(error);

            throw new Error('Failed to update settings');
        }
    };

    const create = async (newSettings: Partial<StaticQuoteTypeMap[StaticQuoteType]>) => {
        try {
            await fetchFromServer(`/static-rate/${type}/${currentZone}`, {
                method: "POST",
                body: JSON.stringify(newSettings),
            });

            await mutate();
        } catch (error) {
            console.error(error);

            throw new Error('Failed to create');
        }
    };

    const deleteQuote = async (id: string) => {
        try {
            await fetchFromServer(`/static-rate/${type}/${currentZone}/${id}`, {
                method: "DELETE",
            });

            await mutate();
        } catch (error) {
            console.error(error);

            throw new Error('Failed to delete');
        }
    };

    return {
        settings: settings as StaticQuoteTypeMap[StaticQuoteType][],
        submitSettings,
        enable,
        disable,
        create,
        deleteQuote,
        error,
        isLoading,
    };
};

export default useStaticRateSettings;