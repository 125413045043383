import { useMemo } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    Box,
    Text,
    Form,
    Flex,
    FlexItem,
} from "@bigcommerce/big-design";

import Input from "../../FormControls/Input";
import Select from "../../FormControls/Select";
import DollarIcon from "../../UI/DollarIcon";
import {
    FlatRateSettings as FlatRateSettingsType,
    StaticQuoteType,
} from "../../../types/staticQuoteSettings";

const schema = yup.object({
    display_name: yup.string(),
    shipping_rate: yup.number().required('Shipping rate is required'),
    type: yup.string().required('Type is required'),
});


interface FlatRateSettingsProps {
    initialSettings: FlatRateSettingsType;
    otherCarrierNames?: string[];
    onSubmit: (settings: Partial<FlatRateSettingsType>) => Promise<void>;
}

const FlatRateSettings: React.FC<FlatRateSettingsProps> = ({
    initialSettings,
    otherCarrierNames = [],
    onSubmit,
}) => {
    const schema = useMemo(() => {
        return yup.object({
            display_name: yup.string().notOneOf(otherCarrierNames, 'Display name must be unique'),
            shipping_rate: yup.number().required('Shipping rate is required'),
            type: yup.string().required('Type is required'),
        });
    }, [otherCarrierNames]);

    const methods = useForm<Partial<FlatRateSettingsType>>({
        defaultValues: {
            display_name: initialSettings?.display_name || 'Flat Rate',
            shipping_rate: initialSettings?.shipping_rate || 0,
            type: initialSettings?.type || 'per_order',
        },
        // @ts-ignore
        resolver: yupResolver(schema),
    });

    const handleSubmit: SubmitHandler<Partial<FlatRateSettingsType>> = async (data) => {
        console.log('data', data);

        await onSubmit(data);
    };

    return (
        <Box>
            <Form id={StaticQuoteType.FLAT_RATE} onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
                <Input
                    type="text"
                    label="Display Name"
                    control={methods.control}
                    name="display_name"
                />
                <Input
                    type="number"
                    label="Shipping Rate"
                    iconLeft={<DollarIcon />}
                    control={methods.control}
                    name="shipping_rate"
                />
                <Select
                    label="Type"
                    options={[
                        { value: 'per_order', content: 'Per Order' },
                        { value: 'per_item', content: 'Per Item' },
                    ]}
                    control={methods.control}
                    name="type"
                />
            </Form>
        </Box>
    );
};





export default FlatRateSettings;