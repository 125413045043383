import { RealTimeRateSettings } from "./RealTimeRateSettings";

export enum UspsPriceType {
    RETAIL = 'RETAIL',
    COMMERCIAL = 'COMMERCIAL',
    CONTRACT = 'CONTRACT',
}

export enum UspsRateIndicator {
    FLAT_RATE_LEGAL = 'FA',
    FLAT_RATE_PADDED = 'FP',
    PARCEL = 'DR',
    FLAT_RATE_BOX_SM = 'FS',
    FLAT_RATE_BOX_MD = 'FB',
    FLAT_RATE_BOX_LG = 'PL',
}

export enum UspsPackageType {
    FLAT_RATE_LEGAL = 'Flat Rate Legal Envelope',
    FLAT_RATE_PADDED = 'Flat Rate Padded Envelope',
    PARCEL = 'Parcel',
    FLAT_RATE_BOX_SM = 'Small Flat Rate Box',
    FLAT_RATE_BOX_MD = 'Medium Flat Rate Box',
    FLAT_RATE_BOX_LG = 'Large Flat Rate Box',
    FLAT_RATE_ENVELOPE = 'Flat Rate Envelope',
}

export enum UspsDeliveryServiceTypes {
    PARCEL_SELECT = 'PARCEL_SELECT',
    PRIORITY_MAIL_EXPRESS = 'PRIORITY_MAIL_EXPRESS',
    PRIORITY_MAIL = 'PRIORITY_MAIL',
    FIRST_CLASS_PACKAGE_SERVICE = 'FIRST_CLASS_PACKAGE_SERVICE',
    USPS_CONNECT_LOCAL = 'USPS_CONNECT_LOCAL',
    USPS_CONNECT_MAIL = 'USPS_CONNECT_MAIL',
    USPS_CONNECT_NEXT_DAY = 'USPS_CONNECT_NEXT_DAY',
    USPS_CONNECT_REGIONAL = 'USPS_CONNECT_REGIONAL',
    USPS_CONNECT_SAME_DAY = 'USPS_CONNECT_SAME_DAY',
    USPS_GROUND_ADVANTAGE = 'USPS_GROUND_ADVANTAGE',
    USPS_RETAIL_GROUND = 'USPS_RETAIL_GROUND',
};


export interface UspsSettings extends RealTimeRateSettings {
    price_type: UspsPriceType;
    rate_indicator: UspsRateIndicator;
    package_type: UspsPackageType;
};
