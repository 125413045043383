import { useState } from 'react';

import {
    Box,
    Grid,
    Modal,
    Text,
} from "@bigcommerce/big-design";

import useStaticQuotesData from '../hooks/useStaticQuotesData';
import { useAppContext } from '../context/AppContext';


import {
    FreeShippingSettings,
    FlatRateSettings,
    ShipBySettings,
    PickupSettings,
    StaticQuoteType,
    StaticRateSettings,
} from '../types/staticQuoteSettings';

import FreeShipping from './Forms/FreeShipping/FreeShippingSettings';
import FlatRate from './Forms/FlatRate/FlatRateSettings';
import ShipBy from './Forms/ShipBy/ShipBySettings';
import Pickup from './Forms/Pickup/PickUpSettings';
import QuoteItem from './QuoteItem';
import Loading from './UI/Loading';
import RateGroup from './UI/RateGroup';

const StaticQuotes: React.FC<{ currentZone: string }> = ({ currentZone }) => {
    const {
        staticQuoteData,
        isLoading,
        error,
        enableStaticQuote,
        disableStaticQuote,
        submitSettings,
        createStaticQuote,
        deleteStaticQuote,
    } = useStaticQuotesData(currentZone);

    const { alertsManager } = useAppContext();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRateProvider, setSelectedRateProvider] = useState<StaticRateSettings | null>(null);
    const [isEnabling, setIsEnabling] = useState(false);
    const [isSubmittingSettings, setIsSubmittingSettings] = useState(false);

    const handleToggle = async (id: string, type: StaticQuoteType) => {
        const quote = staticQuoteData
            .find(({ type: quoteType }) => quoteType === type)
            ?.settings
            ?.find(({ id: quoteId }) => quoteId === id);

        if (quote) {
            const isEnabled = quote.is_enabled;

            if (isEnabled) {
                await disableStaticQuote(id, type);
            } else {
                handleEditSettings(id, type, true);

                await enableStaticQuote(id, type);
            }
        }
    };

    const handleEditSettings = (id: string, type: StaticQuoteType, isEnabling: boolean) => {
        const quote = staticQuoteData
            .find(({ type: quoteType }) => quoteType === type)
            ?.settings
            ?.find(({ id: quoteId }) => quoteId === id);

        setSelectedRateProvider(quote);
        setIsEnabling(isEnabling);
        setIsModalOpen(true);
    };

    const handleCancelSettings = async () => {
        // if this was the first time enabling the provider, disable it on cancel
        if (isEnabling) {
            await handleToggle(selectedRateProvider.id, selectedRateProvider.rate_type);
        }

        setIsModalOpen(false);
    };

    const handleSubmitSettings = async (settings: StaticRateSettings) => {
        setIsSubmittingSettings(true);

        try {
            const newSettings = {
                ...settings,
                is_enabled: true,
            };

            await submitSettings(selectedRateProvider.id, selectedRateProvider.rate_type, newSettings);

            setIsModalOpen(false);
        } catch (error) {
            alertsManager?.add({
                title: 'Error',
                messages: [{
                    text: error?.message || 'Failed to update settings',
                }],
                type: 'error',
                autoDismiss: true,
            });
        } finally {
            setIsSubmittingSettings(false);
        }
    };

    const handleCreateStaticQuote = async (quoteType: StaticQuoteType, newSettings: Partial<StaticRateSettings>) => {
        await createStaticQuote(quoteType, newSettings);
    };

    const handleDeleteStaticQuote = async (id: string, quoteType: string) => {
        await deleteStaticQuote(id, quoteType);
    };

    return (
        <RateGroup heading="Static Shipping Quotes">
            {
                isLoading && (
                    <Loading />
                )
            }
            {
                error && (
                    <Text>Error loading static quotes</Text>
                )
            }
            <Grid>
                {staticQuoteData?.map((quotes, index) => {
                    return quotes?.settings?.map((quote: StaticRateSettings, index: number) => {
                        const {
                            shipping_id,
                            id,
                            ...settings
                        } = quote;

                        const showDeleteButton = (quotes.type !== StaticQuoteType.FREE_SHIPPING) && index > 0;
                        const showCopyButton = quotes.type !== StaticQuoteType.FREE_SHIPPING;

                        const onCopySettings = async () => {
                            await handleCreateStaticQuote(quote.rate_type, settings);
                        };

                        const onDeleteSettings = async () => {
                            await handleDeleteStaticQuote(id, quote.rate_type);
                        };

                        return (
                            <QuoteItem
                                key={index}
                                label={quote.display_name}
                                description={quote.description}
                                isEnabled={quote.is_enabled}
                                onEditSettings={() => handleEditSettings(quote.id, quote.rate_type, !quote.is_enabled)}
                                onToggle={() => handleToggle(quote.id, quote.rate_type)}
                                onCopySettings={showCopyButton ? onCopySettings : undefined}
                                onDeleteSettings={showDeleteButton ? onDeleteSettings : undefined}
                                type={quote.rate_type}
                            />
                        )
                    });
                })}
            </Grid>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                header={`${selectedRateProvider?.display_name} Settings`}
                actions={[
                    {
                        text: 'Cancel',
                        variant: 'subtle',
                        onClick: () => handleCancelSettings(),
                    },
                    {
                        text: 'Save',
                        type: 'submit',
                        form: selectedRateProvider?.rate_type,
                        variant: 'primary',
                        isLoading: isSubmittingSettings,
                    },
                ]}
            >
                <Box padding={'medium'}>
                    {selectedRateProvider?.rate_type === StaticQuoteType.FREE_SHIPPING && (
                        <FreeShipping
                            initialSettings={selectedRateProvider as FreeShippingSettings}

                            onSubmit={handleSubmitSettings}
                        />
                    )}
                    {selectedRateProvider?.rate_type === StaticQuoteType.FLAT_RATE && (
                        <FlatRate
                            initialSettings={selectedRateProvider as FlatRateSettings}
                            otherCarrierNames={staticQuoteData.flatMap((quote) => quote?.settings?.filter(({ id }) => id !== selectedRateProvider.id).map(({ display_name }) => display_name)) || []}
                            onSubmit={handleSubmitSettings}
                        />
                    )}
                    {selectedRateProvider?.rate_type === StaticQuoteType.SHIP_BY && (
                        <ShipBy
                            initialSettings={selectedRateProvider as ShipBySettings}
                            otherCarrierNames={staticQuoteData.flatMap((quote) => quote?.settings?.filter(({ id }) => id !== selectedRateProvider.id).map(({ display_name }) => display_name)) || []}
                            onSubmit={handleSubmitSettings}
                        />
                    )}
                    {selectedRateProvider?.rate_type === StaticQuoteType.PICKUP && (
                        <Pickup
                            initialSettings={selectedRateProvider as PickupSettings}
                            onSubmit={handleSubmitSettings}
                            otherCarrierNames={staticQuoteData.flatMap((quote) => quote?.settings?.filter(({ id }) => id !== selectedRateProvider.id).map(({ display_name }) => display_name)) || []}
                        />
                    )}
                </Box>
            </Modal>
        </RateGroup>
    );
};

export default StaticQuotes;