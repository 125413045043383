import { useMemo } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Form } from '@bigcommerce/big-design';
import * as yup from 'yup';

import Input from '../../FormControls/Input';
import Select from '../../FormControls/Select';
import Checkbox from '../../FormControls/Checkbox';
import DeliveryServiceTable from '../../Tables/DeliveryServiceTable';

import { RealTimeRateType } from '../../../types/RealTimeRateSettings';
import { 
    FedexSettings,
    FedexDropOffTypes,
    FedexPackagingTypes,
    FedexPackingMethods,
    FedexDestinationTypes,
    FedexDeliveryServiceTypes,
    FedexRateRequestTypes,
} from '../../../types/FedexSettings';

import { DeliveryServiceSurchargeType } from "../../../types/DeliveryService";

const snakeCaseToTitleCase = (str: string) => {
    return str.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};

export type FedexSettingsFormProps = {
    initialSettings: FedexSettings;
    onSubmit: (data: Partial<FedexSettings>) => Promise<void>;
    otherCarrierNames: string[];
}

const FedexSettingsForm: React.FC<FedexSettingsFormProps> = ({
    initialSettings,
    onSubmit,
    otherCarrierNames,
}) => {
    const schema = useMemo(() => {
        return yup.object({
            display_name: yup.string().required().notOneOf(otherCarrierNames, 'Display name must be unique'),
            drop_off_type: yup.string().oneOf(Object.values(FedexDropOffTypes)).required(),
            packaging_type: yup.string().oneOf(Object.values(FedexPackagingTypes)).required(),
            packing_method: yup.string().oneOf(Object.values(FedexPackingMethods)).required(),
            rate_type: yup.string().oneOf(Object.values(RealTimeRateType)).required(),
            include_package_value: yup.boolean(),
            destination_type: yup.string().oneOf(Object.values(FedexDestinationTypes)).required(),
            rate_request_type: yup.string().oneOf(Object.values(FedexRateRequestTypes)).required(),
            delivery_services: yup.array().of(yup.object({
                is_enabled: yup.boolean(),
                type: yup.string().oneOf(Object.values(FedexDeliveryServiceTypes)).required(),
                id: yup.string().required(),
                surcharge: yup.number().required(),
                surcharge_type: yup.string().oneOf(Object.values(DeliveryServiceSurchargeType)).required(),
            })),
        });
    }, [otherCarrierNames]);


    const methods = useForm<Partial<FedexSettings>>({
        defaultValues: {
            display_name: initialSettings?.display_name || 'Fedex',
            drop_off_type: initialSettings?.drop_off_type || FedexDropOffTypes.CONTACT_FEDEX_TO_SCHEDULE,
            packaging_type: initialSettings?.packaging_type || FedexPackagingTypes.FEDEX_BOX,
            packing_method: initialSettings?.packing_method || FedexPackingMethods.PACKAGING,
            rate_type: initialSettings?.rate_type || RealTimeRateType.FEDEX,
            include_package_value: initialSettings?.include_package_value || false,
            destination_type: initialSettings?.destination_type || FedexDestinationTypes.RESIDENTIAL,
            rate_request_type: initialSettings?.rate_request_type || FedexRateRequestTypes.LIST,
            delivery_services: initialSettings?.delivery_services || [],
        },
        // @ts-ignore
        resolver: yupResolver(schema),
    });

    console.log({
        state: methods.formState,
        errors: methods.formState.errors,
    });

    return (
        <Form id={RealTimeRateType.FEDEX} onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <Input
                control={methods.control}
                type="text"
                label="Display Name"
                name="display_name"
            />
            <Select
                control={methods.control}
                label="Drop Off Type"
                name="drop_off_type"
                options={Object.values(FedexDropOffTypes).map(value => ({ value, content: snakeCaseToTitleCase(value) }))}
            />

            <Select
                control={methods.control}
                label="Packaging Type"
                name="packaging_type"
                options={Object.values(FedexPackagingTypes).map(value => ({ value, content: snakeCaseToTitleCase(value) }))}
            />

            <Select
                control={methods.control}
                label="Packing Method"
                name="packing_method"
                options={Object.values(FedexPackingMethods).map(value => ({ value, content: snakeCaseToTitleCase(value) }))}
            />

            <Select
                control={methods.control}
                label="Destination Type"
                name="destination_type"
                options={Object.values(FedexDestinationTypes).map(value => ({ value, content: snakeCaseToTitleCase(value) }))}
            />

            <Select
                control={methods.control}
                label="Rate Request Type"
                name="rate_request_type"
                options={Object.values(FedexRateRequestTypes).map(value => ({ value, content: snakeCaseToTitleCase(value) }))}
            />

            <Checkbox
                control={methods.control}
                label="Include Package Value"
                name="include_package_value"
            />

            <Box marginTop="large">
                <DeliveryServiceTable
                    deliveryServices={methods.getValues('delivery_services').map((deliveryService, index) => ({
                        ...deliveryService,
                        index,
                    }))}
                    control={methods.control}
                />
            </Box>
        </Form>
    );
};


export default FedexSettingsForm;