import { RealTimeRateSettings } from "./RealTimeRateSettings";

export enum UpsDeliveryServiceTypes {
    NEXT_DAY_AIR = '01',
    SECOND_DAY_AIR = '02',
    GROUND = '03',
    EXPRESS = '07',
    EXPEDITED = '08',
    STANDARD = '11',
    THREE_DAY_SELECT = '12',
    NEXT_DAY_AIR_SAVER = '13',
    NEXT_DAY_AIR_EARLY_AM = '14',
    SECOND_DAY_AIR_AM = '59',
}

export enum UpsPackagingType {
    CUSTOMER_SUPPLIED = '00',
    UPS_LETTER = '01',
    PACKAGE = '02',
    TUBE = '03',
    PAK = '04',
    EXPRESS_BOX = '21',
}


export interface UpsSettings extends RealTimeRateSettings {
    include_package_value: boolean;
    use_negotiated_rates: boolean;
    show_estimated_delivery_date: boolean;
    packaging_type: UpsPackagingType;
};
