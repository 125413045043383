import { ProgressCircle, Flex } from "@bigcommerce/big-design";

const Loading = () => {
    return (
        <Flex justifyContent="center" alignItems="center">
            <ProgressCircle />
        </Flex>
    );
};

export default Loading;