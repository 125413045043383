
import { Box, GlobalStyles } from '@bigcommerce/big-design'
import { createGlobalStyle } from 'styled-components';
import { createAlertsManager, AlertsManager } from '@bigcommerce/big-design';

const alertsManager = createAlertsManager();

import App from './components/App';

import { theme } from "@bigcommerce/big-design-theme";
import { ThemeProvider } from "styled-components";

import { AppProvider } from './context/AppContext';

const newTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#C018A2",
    primary10: "#F5E6F7",
    primary20: "#E9CFF0",
    primary30: "#DAADEA",
    primary40: "#C89BDF",
    primary50: "#B787D3",
    primary60: "#A373C7",
    primary70: "#8F5EBB",

    secondary: "#FF69B4"
  }
};

// add global styles via styled-components
const CustomGlobalStyles = createGlobalStyle`
  .modal--large .styled__StyledModalContent-sc-b0pzor-1 {
    max-width: 75%;
  }
`;

export default function Root(props) {
  return (
    <Box>
      <AlertsManager manager={alertsManager} />
      <AppProvider props={props} alertsManager={alertsManager}>
        <ThemeProvider theme={newTheme}>
          <GlobalStyles />
          <CustomGlobalStyles />

          <App />
        </ThemeProvider>
      </AppProvider>
    </Box>
  )
}