import { useState } from 'react';
import { Box, Select } from "@bigcommerce/big-design";
import RealTimeQuotes from "./RealTimeQuotes";
import StaticQuotes from "./StaticQuotes";
import useWrappedSwr from "../hooks/use-swr";
import Loading from "./UI/Loading";

const App: React.FC = () => {
    const {
        data: shippingZones,
        isLoading: shippingZonesLoading,
        error: shippingZonesError,
    } = useWrappedSwr('/shipping-zones');

    const [currentZone, setCurrentZone] = useState<string | null>(null);

    const handleZoneChange = (value: string) => {
        setCurrentZone(value);
    };

    if (shippingZonesLoading) {
        return <Loading />;
    }

    return (
        <Box>

            <Box marginBottom={'large'}>
                {/* @ts-ignore */}
                <Select
                    options={shippingZones?.data?.shipping_zones?.map((zone) => ({
                        content: zone.display_name,
                        value: zone.zone_data.id,
                    })) || []}
                    label='Shipping Zone'
                    required
                    onOptionChange={handleZoneChange}
                />
            </Box>
            <Box marginBottom={'xLarge'}>
                {
                    currentZone && (
                        <StaticQuotes currentZone={currentZone} />
                    )
                }
            </Box>
            <Box marginBottom={'xLarge'}>
                {
                    currentZone && (
                        <RealTimeQuotes currentZone={currentZone} />
                    )
                }
            </Box>
        </Box>
    )
};

export default App;