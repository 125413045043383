
import { useState } from "react";

import {
    Box,
    Grid,
    Modal,
    ModalAction,
    Text,
} from "@bigcommerce/big-design";

import { RealTimeRateSettings, RealTimeRateType } from "../types/RealTimeRateSettings";
import { FedexSettings } from "../types/FedexSettings";
import { UpsSettings } from "../types/UpsSettings";
import { UspsSettings } from "../types/UspsSettings";

import useRealTimeQuotesData from '../hooks/useRealTimeQuotesData';

import QuoteItem from "./QuoteItem";
import FedexSettingsForm from "./Forms/Fedex/FedexSettings";
import FedexConnectionForm from "./Forms/Fedex/FedexConnection";
import UPSSettingsForm from "./Forms/UPS/UPSSettings";
import UPSConnectionForm from "./Forms/UPS/UPSConnection";
import UspsSettingsForm from "./Forms/USPS/USPSSettings";
import USPSConnectionForm from "./Forms/USPS/USPSConnection";

import Loading from "./UI/Loading";
import RateGroup from "./UI/RateGroup";
const RealTimeQuotes: React.FC<{ currentZone: string }> = ({ currentZone }) => {
    const {
        quoteData,
        isLoading,
        error,
        enableRealTimeQuote,
        disableRealTimeQuote,
        submitSettings,
    } = useRealTimeQuotesData(currentZone);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRateProvider, setSelectedRateProvider] = useState<RealTimeRateSettings | null>(null);
    const [selectedConnectionProvider, setSelectedConnectionProvider] = useState<RealTimeRateSettings | null>(null);
    const [isEnabling, setIsEnabling] = useState(false);

    const handleToggle = async (id: string, type: RealTimeRateType) => {
        const quote = quoteData.find(({ type: quoteType }) => quoteType === type)?.settings?.find(({ id: quoteId }) => quoteId === id);

        if (quote) {
            const isEnabled = quote.is_enabled;

            if (isEnabled) {
                await disableRealTimeQuote(id, type);
            } else {
                handleEditConnectionSettings(id, type, true);

                await enableRealTimeQuote(id, type);
            }
        }
    };

    const handleEditSettings = (id: string, type: RealTimeRateType, isEnabling: boolean) => {
        const quote = quoteData
            .find(({ type: quoteType }) => quoteType === type)
            ?.settings
            ?.find(({ id: quoteId }) => quoteId === id);

        if (!quote) throw new Error('Quote not found');

        setSelectedRateProvider(quote);
        setIsEnabling(isEnabling);
        setIsModalOpen(true);
    };

    const handleSubmitSettings = async (settings: Partial<RealTimeRateSettings>) => {
        if (selectedRateProvider) {
            setIsModalOpen(false);

            await submitSettings(selectedRateProvider.id, selectedRateProvider.rate_type, settings);

            setSelectedRateProvider(null);
        }

        if (selectedConnectionProvider) {
            setIsModalOpen(false);
            await submitSettings(selectedConnectionProvider.id, selectedConnectionProvider.rate_type, settings);
            setSelectedConnectionProvider(null);
        }
    };

    const handleCancelSettings = async () => {
        // if this was the first time enabling the provider, disable it on cancel
        if (isEnabling) {
            const rateId = selectedRateProvider?.id || selectedConnectionProvider?.id;
            const rateType = selectedRateProvider?.rate_type || selectedConnectionProvider?.rate_type;

            await handleToggle(rateId, rateType);
        }

        setIsModalOpen(false);
        setSelectedRateProvider(null);
        setSelectedConnectionProvider(null);
    };

    const handleEditConnectionSettings = async (id: string, type: RealTimeRateType, isEnabling: boolean) => {
        const quote = quoteData
            .find(({ type: quoteType }) => quoteType === type)
            ?.settings
            ?.find(({ id: quoteId }) => quoteId === id);

        if (!quote) throw new Error('Quote not found');

        setSelectedConnectionProvider(quote);
        setIsEnabling(isEnabling);
        setIsModalOpen(true);
    };

    return (
        <RateGroup heading="Real-time Shipping Quotes">
            {
                isLoading && (
                    <Loading />
                )
            }
            {
                error && (
                    <Text>
                        Error loading real-time quotes. Please try again later.
                    </Text>
                )
            }
            <Grid>

                {quoteData?.map((quote) => {
                    return quote?.settings?.map((settings: RealTimeRateSettings, index: number) => {
                        return (
                            <QuoteItem
                                key={index}
                                label={settings.display_name}
                                isEnabled={settings.is_enabled}
                                type={settings.rate_type}
                                onToggle={() => handleToggle(settings.id, settings.rate_type)}
                                onEditSettings={() => handleEditSettings(settings.id, settings.rate_type, !settings.is_enabled)}
                                onEditConnectionSettings={() => handleEditConnectionSettings(settings.id, settings.rate_type, false)}
                            />
                        );
                    });
                })}
            </Grid>

            <Box className={selectedConnectionProvider ? 'modal--large' : ''}>
                <FormModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    header={`${selectedRateProvider?.display_name || selectedConnectionProvider?.display_name} ${selectedRateProvider ? 'Settings' : 'Connection Settings'}`}
                    actions={[
                        {
                            text: 'Cancel',
                            variant: 'subtle',
                            onClick: () => handleCancelSettings(),
                        },
                        {
                            text: 'Save',
                            type: 'submit',
                            form: selectedRateProvider?.rate_type || selectedConnectionProvider?.rate_type,
                            variant: 'primary',
                        },
                    ]}
                >
                    <RateProviderSettingsForm
                        rateType={selectedRateProvider?.rate_type}
                        initialSettings={selectedRateProvider}
                        onSubmit={handleSubmitSettings}
                        otherCarrierNames={quoteData.flatMap((quote) => quote?.settings?.filter(({ id }) => id !== selectedRateProvider?.id).map(({ display_name }) => display_name)) || []} 
                    />

                    <RateProviderConnectionForm
                        rateType={selectedConnectionProvider?.rate_type}
                        initialSettings={selectedConnectionProvider}
                        onSubmit={handleSubmitSettings}
                    />
                </FormModal>
            </Box>
        </RateGroup>
    );
};

interface RateProviderSettingsFormProps {
    rateType: RealTimeRateType;
    initialSettings: RealTimeRateSettings;
    onSubmit: (data: Partial<RealTimeRateSettings>) => Promise<void>;
    otherCarrierNames: string[];
}

const RateProviderSettingsForm: React.FC<RateProviderSettingsFormProps> = ({ rateType, initialSettings, onSubmit, otherCarrierNames }) => {
    switch (rateType) {
        case RealTimeRateType.FEDEX:
            return <FedexSettingsForm initialSettings={initialSettings as FedexSettings} onSubmit={onSubmit} otherCarrierNames={otherCarrierNames} />;
        case RealTimeRateType.UPS:
            return <UPSSettingsForm initialSettings={initialSettings as UpsSettings} onSubmit={onSubmit} otherCarrierNames={otherCarrierNames} />;
        case RealTimeRateType.USPS:
            return <UspsSettingsForm initialSettings={initialSettings as UspsSettings} onSubmit={onSubmit} otherCarrierNames={otherCarrierNames} />;
        default:
            return null;
    }
};

interface RateProviderConnectionFormProps {
    rateType: RealTimeRateType;
    initialSettings: RealTimeRateSettings;
    onSubmit: (data: Partial<RealTimeRateSettings>) => Promise<void>;
}

const RateProviderConnectionForm: React.FC<RateProviderConnectionFormProps> = ({ rateType, initialSettings, onSubmit }) => {
    switch (rateType) {
        case RealTimeRateType.FEDEX:
            return <FedexConnectionForm initialSettings={initialSettings as FedexSettings} onSubmit={onSubmit} />;
        case RealTimeRateType.UPS:
            return <UPSConnectionForm initialSettings={initialSettings as UpsSettings} onSubmit={onSubmit} />;
        case RealTimeRateType.USPS:
            return <USPSConnectionForm initialSettings={initialSettings as UspsSettings} onSubmit={onSubmit} />;
        default:
            return null;
    }
};

interface FormModalProps {
    isOpen: boolean;
    onClose: () => void;
    header: string;
    children: React.ReactNode;
    actions: ModalAction[];
}

const FormModal: React.FC<FormModalProps> = ({
    isOpen,
    onClose,
    header,
    children,
    actions,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} header={header} actions={actions}>
            <Box padding={'medium'}>
                {children}

            </Box>
        </Modal>
    );
};



export default RealTimeQuotes;