export interface StaticRateSettings {
    id: string;
    is_enabled: boolean;
    display_name: string;
    shipping_id: string;
    description: string;
    icon_src: string;
    rate_type: StaticQuoteType;
};

export interface FreeShippingSettings extends StaticRateSettings {
    limit_to_min_order_value: boolean;
    min_order_value?: number;
    use_discounted_subtotal: boolean;
    ignore_fixed_shipping_products: boolean;
};

export interface ShipBySettings extends StaticRateSettings {
    charge_shipping: 'by_weight' | 'by_order_total' | 'by_product_type';
    default_shipping_cost: number;
    type: 'flat' | 'percent';
    weight_unit?: 'lb' | 'kg';
    ranges: {
        from_value: number;
        to_value: number;
        cost: number;
        id?: string;
    }[];
};

export interface PickupSettings extends StaticRateSettings {
    shipping_id: string;
    is_enabled: boolean;
    display_name: string;
};

export interface FlatRateSettings extends StaticRateSettings {
    shipping_rate: number;
    type: 'per_order' | 'per_item';
};

export enum StaticQuoteType {
    FREE_SHIPPING = 'free-shipping',
    FLAT_RATE = 'flat-rate',
    SHIP_BY = 'ship-by',
    PICKUP = 'pickup',
};

export interface StaticQuoteTypeMap {
    [StaticQuoteType.FREE_SHIPPING]: FreeShippingSettings;
    [StaticQuoteType.FLAT_RATE]: FlatRateSettings;
    [StaticQuoteType.SHIP_BY]: ShipBySettings;
    [StaticQuoteType.PICKUP]: PickupSettings;
};

//52568
