import { Controller } from 'react-hook-form';
import { Input as BDInput, FormGroup } from '@bigcommerce/big-design';

const Input = ({ control, name, label = '', required = true, ...otherInputProps }) => {

    const {
        type,
    } = otherInputProps;

    const render = ({ field, fieldState }) => {
        // if the input is a number, we need to convert it before setting the value
        const value = type === 'number' ? field.value?.toString() : field.value;
        const onChange = (event) => {
            const value = event.target.value;
            field.onChange(type === 'number' ? parseFloat(value) : value);
        };

        return (
            <FormGroup>
                <BDInput
                    label={label === '' ? null : label}
                    {...otherInputProps}
                    {...field}
                    value={value}
                    onChange={onChange}
                    error={fieldState.error?.message}
                    required={required}
                />
            </FormGroup>
        );
    }

    return (
        <Controller
            control={control}
            name={name}
            render={render}
        />
    );
};

export default Input;